import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './TripAddressInput.module.scss';
import { routeAddressModel } from '../../../models/routeAddress';
import DatePicker, { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import InputMask from "react-input-mask";
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import "react-datepicker/dist/react-datepicker.css";
import { Row } from 'react-bootstrap';
import { AddressService } from '../../../services/address.service';

import { Typeahead } from 'react-bootstrap-typeahead';

const TripAddressInput = (props) => {
  const [ data, setData ] = useState(routeAddressModel);
  const addressService = new AddressService();

  useEffect(() => {
    registerLocale("pt-BR", ptBR);
    if(props.data){
      setData(props.data);
    }
  }, [])

  const onChange = (newData) => {
    setData(newData)
    props.onDataChange(newData, props.index);
  }

  const checkCep = (e) => {
    const normalizedCep = e.target.value.replace(/\D/g, '');
    if(normalizedCep.length < 8) return
    addressService.getAddressByCEP(normalizedCep).then((address) => {
      setData({
        ...data,
        street: address.logradouro,
        district: address.bairro,
        city: address.localidade,
        state: address.uf
      })
    }).catch((e) => {
      
    })
  } 

  const handleAddressChange = (e) => {
    const selectedAddress = e[0];
    const newData = {...data, ...selectedAddress};
    setData(newData);
    props.onDataChange(newData, props.index);
  }

  const handleChange = (e) => {
    const newData = { ...data, [e.target.name]: e.target.value }
    setData(newData);
    props.onDataChange(newData, props.index);
  }

  const onFocus= ({ target }) => {
    target.setAttribute('autoComplete', 'off');
  }

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.addressHeader}>
        <h5>{props.title}</h5>
      </div>
      <Row>
      <Form.Group as={Col} md="12" controlId="addressList">
          <Form.Label>Selecione um endereço<strong className='text-danger'>*</strong></Form.Label>
          <Typeahead
            id='filterAdd'
            labelKey="name"
            onChange={handleAddressChange}
            options={props.addressesList}
            placeholder='Pesquisar'
            onFocus={onFocus}
          />
        </Form.Group>
        <Form.Group as={Col} md="12" controlId="addressName">
          <Form.Label>Nome do endereço <strong className='text-danger'>*</strong></Form.Label>
          <Form.Control
            required
            name='name'
            type="text"
            placeholder="Nome do endereço"
            value={data.name} 
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="streetInput">
          <Form.Label>Rua <strong className='text-danger'>*</strong></Form.Label>
          <Form.Control
            required
            name='street'
            type="text"
            placeholder="Rua"
            value={data.street} 
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} md="2" controlId="numberInput">
          <Form.Label>Número</Form.Label>
          <Form.Control
            name='number'
            type="text"
            placeholder="Número"
            value={data.number} 
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} md="4" controlId="districtInput">
          <Form.Label>Bairro</Form.Label>
          <Form.Control
            name='district'
            type="text"
            placeholder="Bairro"
            value={data.district} 
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="cityInput">
          <Form.Label>Cidade</Form.Label>
          <Form.Control
            name='city'
            type="text"
            placeholder="Cidade"
            value={data.city} 
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} md="1" controlId="stateInput">
          <Form.Label>Estado</Form.Label>
          <Form.Control
            name='state'
            type="text"
            placeholder="Estado"
            value={data.state} 
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="cepInput">
          <Form.Label>CEP</Form.Label>
          <InputMask placeholder='CEP' name='cep' className='form-control' value={data.cep} onChange={handleChange} onBlur={checkCep} mask="99999-999"></InputMask>
        </Form.Group>
        <Form.Group as={Col} md="5" controlId="complementInput">
          <Form.Label>Ponto de referência</Form.Label>
          <Form.Control
            name='complement'
            type="text"
            placeholder="Ponto de referência"
            value={data.complement} 
            onChange={handleChange}
          />
        </Form.Group>
        {!props.last && (
          <Form.Group as={Col} md="2" controlId="dateTimeInput">
            <Form.Label>Data e hora da partida <strong className='text-danger'>*</strong></Form.Label>
            <DatePicker 
              required
              showIcon
              selected={data.datetime}
              onChange={date => onChange({...data, datetime: date})}
              className="form-control"
              minDate={new Date()}
              showTimeSelect
              withPortal
              locale={"pt-BR"}
              timeFormat="p"
              timeIntervals={5}
              dateFormat="Pp"
              fixedHeight/>
          </Form.Group>
      )}
      </Row>
      
    </div>
  );
}

TripAddressInput.propTypes = {
  addressesList: PropTypes.array,
  data: PropTypes.object,
  title: PropTypes.string,
  last: PropTypes.bool,
  onDataChange: PropTypes.func,
  index: PropTypes.number
};

TripAddressInput.defaultProps = {};

export default TripAddressInput;
