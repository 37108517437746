import React from 'react';
import PropTypes from 'prop-types';
import styles from './Passengers.module.scss';
import { AuthContext } from '../../../providers/AuthProvider';
import { AddressService } from '../../../services/address.service';
import { AddressColumns, HomePassengersColumns, PassengersColumns, customSort } from '../../../utils/dataTableColumns';
import DataTable from 'react-data-table-component';
import TableExpanded from '../../ui/tableExpanded/tableExpanded';
import { PassengerService } from '../../../services/passengers.service';
import { Button } from 'react-bootstrap';
import AddressesFilterModal from '../../ui/Filters/AddressesFilterModal/AddressesFilterModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserService } from '../../../services/user.service';

class Passengers extends React.Component {
  static contextType = AuthContext;
  
  data = []
  ownersList = []
  selectedOwner = '';

  constructor(props) {
    super(props);
    this.state = {
      showFilterModal: false
    }
  }

  async componentDidMount() {
    this.user = this.context.user
    this.passengerService = new PassengerService(this.user);
    this.passengerService.getUserPassengers().then((result)=> {
      this.data = result;
      if(!this.user.userInfo.isAdmin) {
        this.forceUpdate();
      } else {
        this.usersService = new UserService(this.user);
        this.usersService.getAllUsers().then((users) => {
          this.ownersList = users.map((user) => {return {id: user.uid, name: user.name}})
          this.forceUpdate();
        })
      }
    })
  }

  onFilterData = (filterData) => {
    console.log(filterData);
    this.selectedOwner = filterData.ownerName;
    this.passengerService.getUserPassengers(filterData.ownerId, false).then((result) => {
      this.data = result;
      this.forceUpdate();
      this.onModalClose();
    })
  }

  onModalClose() {
    this.setState({ showFilterModal: false });
    return this.forceUpdate();
  }

  onRowClick = (uid) => {
    return this.props.navigate("/passenger/" + uid)
  }

  render() {
    if(!this.user) return <div></div>;
    return (
      <div className={styles.Passengers}>
        <div className={styles.Header}>
          <h1>Passageiros</h1>
          <div className={styles.HeaderItems}>
            <div className={styles.StatusContainer}>
            </div>
            <div className={styles.buttonContainer }>
              <button className={styles.SecondaryButton} onClick={() => this.props.navigate("/passengers/create")}>Adicionar Passageiro</button>
            </div>
          </div>
        </div>
        <div className={styles.ListData}>
          <div>
            {
              this.user.userInfo.isAdmin && 
              <div className={styles.Filters}>
                <Button variant='outline-warning' className='search-button' onClick={() => this.setState({ showFilterModal: true })}><FontAwesomeIcon icon="fa-solid fa-magnifying-glass"></FontAwesomeIcon></Button>
                <AddressesFilterModal type='passageiros' show={Boolean(this.state.showFilterModal)} owners={this.ownersList} onClose={() => this.onModalClose()} onFilter={this.onFilterData}></AddressesFilterModal>
                { this.selectedOwner && <h6>Exibindo passageiros: {this.selectedOwner} </h6> }
              </div>
            }
            <DataTable
              pagination
              columns={HomePassengersColumns(this.onRowClick, this.user.userInfo.isAdmin)}
              data={this.data}
              sortFunction={customSort}
              noDataComponent="Não existem registros para serem exibidos."
              ></DataTable>
          </div>
        </div>
      </div>
    )
  }
}
  
Passengers.propTypes = {};

Passengers.defaultProps = {};

export default Passengers;
