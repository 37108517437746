import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './trip.module.scss';
import TripAddressInput from '../../../../ui/TripAddressInput/TripAddressInput';

const Trip = (props) => {
  const [ data, setData ] = useState([]);

  useEffect(() => {
    setData(props.Addresses);
  }, [])

  useEffect(() => {
    props.setaddresses(data)
  }, [data])

  const onAddressChange = (newData, index) => {
    let newAddresses = data;
    newAddresses[index].address = newData;
    setData(newAddresses);
  }

  return (
    <div className={styles.Trip}>
      {
        data.map((address, index) => <TripAddressInput title={address.title} last={index === data.length - 1 } data={address.address} index={index} onDataChange={onAddressChange} key={'trip'+index} addressesList={props.addressList}/>)
      }
    </div>
  );
} 

Trip.propTypes = {
  Addresses: PropTypes.array,
  setaddresses: PropTypes.func,
  addressList: PropTypes.array
};

Trip.defaultProps = {};

export default Trip;
