import { Exception } from "sass";
import { getInitialDate } from "./utils";
import { SolicitationStatusModel } from "../../consts/solicitationStatus";

export function setEstimativeCreated(solicitationData) {
    if(solicitationData.estimate.length === 0 || solicitationData.status !== SolicitationStatusModel.created.value) {
        throw new Exception({code: 401, message: 'O status não pode ser atribuido a solicitação pois não atende aos requisitos necessários.'})
    }
    if(solicitationData.type === 'O'){
        return SolicitationStatusModel.estimativeCreated;
    }
    return SolicitationStatusModel.estimativeAproved;
}

export function setEstimativeAproved(solicitationData) {
    if(solicitationData.type === 'O' && solicitationData.status !== SolicitationStatusModel.estimativeCreated.value) {
        throw new Exception({code: 401, message: 'O status não pode ser atribuido a solicitação pois não atende aos requisitos necessários.'})
    }
    return SolicitationStatusModel.estimativeAproved;
}

export function setAssociationAproved(solicitationData) {
    if(solicitationData.status === SolicitationStatusModel.created.value && !solicitationData.association) {
        throw new Exception({code: 401, message: 'O status não pode ser atribuido a solicitação pois não atende aos requisitos necessários.'})
    }
    return SolicitationStatusModel.estimativeAproved;
}

export function setDriverSelected(solicitationData) {
    if(solicitationData.status !== SolicitationStatusModel.estimativeAproved.value || !solicitationData.driver) {
        throw new Exception({code: 401, message: 'O status não pode ser atribuido a solicitação pois não atende aos requisitos necessários.'})
    }
    return SolicitationStatusModel.driverSelected
}

export function setDriverAproved(solicitationData) {
    if(solicitationData.status !== SolicitationStatusModel.driverSelected.value) {
        throw new Exception({code: 401, message: 'O status não pode ser atribuido a solicitação pois não atende aos requisitos necessários.'})
    }
    return SolicitationStatusModel.driverAproved
}

export function setDriverOnTheWay (solicitationData) {
    const initialDate = getInitialDate(solicitationData);
    const minValidDate = new Date(initialDate);
    minValidDate.setHours(minValidDate.getHours() - 12);
    const actualDate = new Date();
    if(solicitationData.status !== SolicitationStatusModel.driverAproved.value || (actualDate < minValidDate)) {
        throw new Exception({code: 401, message: 'O status não pode ser atribuido a solicitação pois não atende aos requisitos necessários.'})
    }
    return SolicitationStatusModel.driverOnTheWay
}

export function setDriverWaiting(solicitationData) {
    const initialDate = getInitialDate(solicitationData);
    const minValidDate = new Date(initialDate);
    minValidDate.setHours(minValidDate.getHours() - 1);
    const actualDate = new Date();
    if(![SolicitationStatusModel.driverOnTheWay.value, SolicitationStatusModel.passangerDisembarked.value].includes(solicitationData.status) || (actualDate < minValidDate)) {
        throw new Exception({code: 401, message: 'O status não pode ser atribuido a solicitação pois não atende aos requisitos necessários.'})
    }
    return SolicitationStatusModel.driverWaiting
}

export function setTripInProgress(solicitationData) {
    if(solicitationData.status !== SolicitationStatusModel.driverWaiting.value) {
        throw new Exception({code: 401, message: 'O status não pode ser atribuido a solicitação pois não atende aos requisitos necessários.'})
    }
    return SolicitationStatusModel.tripInProgress
}

export function setPassangerDisembarked(solicitationData) {
    if(solicitationData.status !== SolicitationStatusModel.tripInProgress.value) {
        throw new Exception({code: 401, message: 'O status não pode ser atribuido a solicitação pois não atende aos requisitos necessários.'})
    }
    return SolicitationStatusModel.passangerDisembarked
}

export function setTripComplete(solicitationData) {
    if(solicitationData.status !== SolicitationStatusModel.tripInProgress.value) {
        throw new Exception({code: 401, message: 'O status não pode ser atribuido a solicitação pois não atende aos requisitos necessários.'})
    }
    return SolicitationStatusModel.tripCompleted
}

export function setSolicitationCanceled(_) {
    return SolicitationStatusModel.solicitationCanceled
}