import { getDoc, getDocs, doc, query, collection, addDoc, where, updateDoc, orderBy } from "firebase/firestore";
import { db } from "../utils/fbConnector";
import { SolicitationStatusModel } from "../consts/solicitationStatus";

export function SolicitationService (user) {

    const STATUS_TYPES = {
        created: "CR",
        budgeted: "BU",
        aproved: "AP",
        cancelled: "CA",
    }

    const formatAddresses = (address) => {
        const origin = `${address.name || ''} - ${address.street}, ${address.number}, ${address.district}, ${address.city}, ${address.state} - ${address.cep}`
        return origin;
    }    

    
    const getAllUserSolicitations = (showAllSolicitations = false, userUid = user.uid) => {
        return new Promise(async (resolve) => {
            let solicitations = [];
            let q;
            if(user.userInfo && user.userInfo.isAdmin){
                q = query(collection(db, "solicitations"), orderBy('startDate'));
            }else if(user.userInfo && user.userInfo.isDriver) {
                q = query(collection(db, "solicitations"), orderBy('startDate'), where("driver", "==", userUid));
            } else {
                q = query(collection(db, "solicitations"), orderBy('startDate'), where("owner", "==", userUid));
            }
            solicitations = await getDocs(q);
            let result = []
            solicitations.forEach(solicitation => {
                const doc = solicitation.data();
                if(!showAllSolicitations && [SolicitationStatusModel.tripCompleted.value, SolicitationStatusModel.solicitationCanceled.value].includes(doc.status)) return;
                let initial_passenger = '';
                let initial_route = '';
                let end_route = '';
                if(doc.routeAdresses.length > 0) {
                    initial_route = doc.routeAdresses[0];
                    end_route = doc.routeAdresses[doc.routeAdresses.length - 1];
                }
                if(doc.passengers.length > 0) {
                    initial_passenger = doc.passengers[0];
                }
                result.push({
                    uid: solicitation.id,
                    voucher: doc.voucher,
                    date: initial_route.address.datetime.toDate().toISOString(),
                    passenger: doc.passengers,
                    origin: formatAddresses(initial_route.address),
                    destiny: formatAddresses(end_route.address),
                    status: doc.status,
                    driverId: doc.driver
                })
            })
            resolve(result);
        })
    };

    const getDriversSolicitation = (driverId, startDate, endDate) => {
        return new Promise(async (resolve) => {
            let solicitations = [];
            // TODO: Rever a lógica de busca de solicitações para exibição
            let q = query(collection(db, "solicitations"), where("driver", "==", driverId), 
                                                           where("startDate", ">=", new Date()),
                                                           where("startDate", "<=", endDate));
            solicitations = await getDocs(q);
            let result = []
            solicitations.forEach(solicitation => {
                const doc = solicitation.data();
                let initial_route = doc.routeAdresses[0];
                let end_route = doc.routeAdresses[doc.routeAdresses.length - 1];
                result.push({
                    uid: solicitation.id,
                    voucher: doc.voucher,
                    startDate: doc.startDate,
                    endDate: doc.endDate,
                    origin: formatAddresses(initial_route.address),
                    destin: formatAddresses(end_route.address),
                    status: doc.status,
                })
            })
            resolve(result);
        })
    };

    const generateFilteredSolicitationsData = (filters) => {
        return new Promise(async (resolve) => {
            let driverWhere = null;
            let statusWhere = null;
            let startDateWhere = null;
            let endDateWhere = null;
            let userWhere = null;
            const extrafilters = [orderBy('startDate')];
            if(filters.driver) {
                driverWhere = where('driver', '==', filters.driver)
                extrafilters.push(driverWhere);
            }
            if(user.userInfo && user.userInfo.isDriver) {
                driverWhere = where("driver", "==", user.uid);
                extrafilters.push(driverWhere);
            }
            if(user.userInfo && !user.userInfo.isAdmin && !user.userInfo.isDriver) {
                userWhere = where("owner", "==", user.uid)
                extrafilters.push(userWhere);
            }
            if(filters.status.length) {
                statusWhere = where('status', 'in', filters.status);
                extrafilters.push(statusWhere);
            } else {
                statusWhere = where('status', 'not-in', [SolicitationStatusModel.tripCompleted.value, SolicitationStatusModel.solicitationCanceled.value])
                extrafilters.push(statusWhere);
            }
            if(filters.startDate && filters.endDate) {
                startDateWhere = where('startDate', '>=', filters.startDate);
                endDateWhere = where('endDate', '<=', filters.endDate);
                extrafilters.push(startDateWhere)
                extrafilters.push(endDateWhere);
            }
            const q = query(collection(db, "solicitations"), ...extrafilters);
            const solicitations = await getDocs(q);
            let result = [];
            solicitations.forEach(solicitation => {
                const doc = solicitation.data();
                doc.uid = solicitation.id;
                result.push(doc);
            })
            resolve(result);
        })
    }

    const filterSolicitationsv2 = (status, driver, startDate, endDate) => {
        return new Promise(async (resolve) => {
            const filters = {
                driver,
                status,
                startDate,
                endDate
            }
            let solicitations = []
            generateFilteredSolicitationsData(filters).then((result) => {
                result.forEach(doc => {
                    let initial_route = doc.routeAdresses[0];
                    let initial_passenger = '';
                    let end_route = doc.routeAdresses[doc.routeAdresses.length - 1];
                    if(doc.passengers.length > 0) {
                        initial_passenger = doc.passengers[0];
                    }
                    solicitations.push({
                        uid: doc.uid,
                        voucher: doc.voucher,
                        date: doc.startDate.toDate().toISOString(),
                        origin: formatAddresses(initial_route.address),
                        destiny: formatAddresses(end_route.address),
                        passenger: doc.passengers,
                        status: doc.status,
                        driverId: doc.driver
                    })
                })
                resolve(solicitations);
            })
        });
    };


    const getSolicitation = uid => {
        return new Promise(async (resolve, reject) => {
            const docSnap = await getDoc(doc(db, 'solicitations', uid));
            if (docSnap.exists()) {
                resolve(docSnap.data())
            } else {
                reject("No such document!");
            }
        })
    }
    const createSolicitation = (data, status=STATUS_TYPES.created, customOwner={}) => {
        return new Promise(async (resolve, reject) => {
            getAllUserSolicitations(true, customOwner.uid || user.uid).then((result) => {
                data.voucher = data.voucher ? data.voucher : `${customOwner.voucher || user.userInfo.voucherId}-${('000' + result.length).slice(-4)}`;
                data.owner = customOwner.uid || user.uid;
                data.status = status;
                try{
                  addDoc(collection(db, `solicitations`), data)
                  resolve()
                } catch (e) {
                  reject(e);
                }
            })
        })
    };
    const updateSolicitation = (data, uid) => {
        return new Promise(async (resolve, reject) => {
            const docRef = doc(db, 'solicitations', uid)
            const docSnap = await getDoc(docRef);
            if(!docSnap.exists()) reject('Não encontramos o novo carro');
            updateDoc(docRef, data).then((result) => {
                resolve();
            }).catch(e => {
                reject(e)
            })
        })
    };
    const deleteSolicitation = voucher => {
    }

    return {
        formatAddresses,
        createSolicitation,
        getSolicitation,
        getAllUserSolicitations,
        updateSolicitation,
        deleteSolicitation,
        getDriversSolicitation,
        generateFilteredSolicitationsData,
        filterSolicitationsv2
    }
}