import styles from './UsersFilterModal.module.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { Typeahead } from 'react-bootstrap-typeahead';

const UsersFilterModal = (props) => {

  const [ filterData, setFilterData ] = useState({ownerId: '', ownerName: ''});

  const handleInfoChange = (e) => {
    const ownerName = e[0].name
    
    setFilterData({...filterData, ownerId: e[0].id, ownerName: ownerName})
  }



  const handleOnFilter = () => {
    props.onFilter(filterData);
  }

  const handleOnCleanFilter = () => {
    console.log(props.owners)
    setFilterData({ownerId: '', ownerName: ''});
    props.onFilter({ownerId: '', ownerName: ''});
  }

  const onFocus= ({ target }) => {
    target.setAttribute('autoComplete', 'off');
  }

  return (
    <Modal show={props.show} onHide={props.onClose} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
          <Modal.Title>Filtros de usuários</Modal.Title>
        </Modal.Header>
      <Modal.Body className={styles.SolicitationModal}>
        <Form.Group controlId="ownerInput">
          <Form.Label>Informe o nome do usuário</Form.Label>
          <Typeahead
            id='id'
            labelKey="name"
            onChange={handleInfoChange}
            options={props.owners}
            placeholder='Pesquisar'
            onFocus={onFocus}
          />
        </Form.Group>
        <div className={styles.buttons}>
          <button className={styles.PrimaryButton} onClick={handleOnFilter}>Filtrar</button>
          <button className={styles.SecondaryButton} onClick={handleOnCleanFilter}>Limpar filtro</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

UsersFilterModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  owners: PropTypes.array
};

UsersFilterModal.defaultProps = {};

export default UsersFilterModal;
