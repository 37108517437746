import React, { useContext, useEffect, useState } from 'react';
import styles from './register.module.scss';
import { useNavigate } from 'react-router-dom';
import { userDataModel } from '../../../models/userData';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import AvatarInput from '../../ui/avatarInput/avatarInput';
import { AuthContext } from '../../../providers/AuthProvider';
import { validators } from '../../../utils/validators';
import { UserService } from '../../../services/user.service';

const Register = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(AuthContext)
  const formValidators = new validators(user);
  const [ data, setData ] = useState(userDataModel)
  const [ validated, setValidated ] = useState(false)
  const [ userPhoto, setUserPhoto] = useState();
  const [ userCredentials, setUserCredentials] = useState();
  const [ errors, setErrors ] = useState();
  const userService = new UserService(user);

  useEffect(() => {
    if(user) {
      setUserCredentials(user);
      setData(data => {return {...data, email: user.email}})
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if((!errors || Object.keys(errors).length === 0) && validated){
      sendToFirestore();
    };
  }, [errors]) // eslint-disable-line react-hooks/exhaustive-deps
    
  const handleSubmit = (event) => {
    setErrors({});
    event.preventDefault();
    formValidators.validateUserForm(data).then((result)=> {
      setErrors(result);
      setValidated(true);
    })
  }

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleChangeCheck = (e) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  }

  const handleAddressChange = (e) => {
    setData({ ...data, address: {...data.address, [e.target.name]: e.target.value }});
  }

  const sendToFirestore = async () => {
    const file = userPhoto;
    userService.createUser(userCredentials.uid, data, file).then((result) => {
      userService.getUser(result).then((result) => {
        setUser({...user, userInfo: result});
        navigate('/');
      }).catch((err) => {
        alert(err);
      })
    }).catch(error => {
      alert(error);
    })
  };
    return (
      <Form noValidate onSubmit={handleSubmit} className={styles.Register}>
        <div className={styles.sectionContainer}>
          <h6>Informações de login</h6>
          <Row className="justify-content-md-center mb-3 mt-3">
            <Col xs={6} md={1}>
              <AvatarInput onDataChange={(event) => setUserPhoto(event)} />
            </Col>
          </Row>
          <Row className="justify-content-md-center mb-3">
            <Form.Group as={Col} md="4" controlId="emailInput">
              <Form.Label>Email</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.email)}
                readOnly
                disabled
                name='email'
                type="email"
                placeholder="Insira seu e-mail para logar"
                value={data.email} 
                onChange={handleChange}
              />
              {errors?.email && <Form.Control.Feedback type='invalid'>{errors?.email}</Form.Control.Feedback>}
            </Form.Group>
          </Row>
        </div>
        <div className={styles.sectionContainer}>
          <h6>Identificação</h6>
          <Row className="mb-3">
            <Form.Group as={Col} md="5" controlId="nameInput">
              <Form.Label>Nome da empresa/usuário</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.name)}
                type="text"
                name='name'
                placeholder="Nome da empresa/usuário"
                value={data.name} 
                onChange={handleChange}
              />
              {errors?.name && <Form.Control.Feedback type='invalid'>{errors?.name}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="cpfInput">
              <Form.Label>CPF/CNPJ</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.cpf_cnpj)}
                type="text"
                name='cpf_cnpj'
                placeholder="CPF/CNPJ"
                value={data.cpf_cnpj} 
                onChange={handleChange}
              />
              {errors?.cpf_cnpj && <Form.Control.Feedback type='invalid'>{errors?.cpf_cnpj}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="rhInput">
              <Form.Label>RG</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.rg)}
                type="text"
                placeholder="RG"
                name='rg'
                value={data.rg} 
                onChange={handleChange}
              />
              {errors?.rg && <Form.Control.Feedback type='invalid'>{errors?.rg}</Form.Control.Feedback>}
            </Form.Group>
          </Row>
        </div>
        <div className={styles.sectionContainer}>
          <h6>Identificação</h6>
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="contactEmailInput">
              <Form.Label>Email para contato</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.contact_email)}
                type="text"
                placeholder="Insira seu email para contato"
                value={data.contact_email} 
                name='contact_email'
                onChange={handleChange}
              />
              {errors?.contact_email && <Form.Control.Feedback type='invalid'>{errors?.contact_email}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="phoneInput">
              <Form.Label>Telefone</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.contact_phone)}
                type="text"
                placeholder="Telefone para contato"
                mask="(99)9999-9999"
                name='contact_phone'
                value={data.contact_phone} 
                onChange={handleChange}
              />
              {errors?.contact_phone && <Form.Control.Feedback type='invalid'>{errors?.contact_phone}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="celphoneInput">
              <Form.Label>Celular</Form.Label>
              {data.international && <Form.Control.Feedback type='alert'>*(digite o código do país seguido do número de celular. Ex.: '+55....')</Form.Control.Feedback>}
              <Form.Control
                isInvalid={Boolean(errors?.contact_celphone)}
                type="text"
                placeholder="Celular para contato"
                mask={!data.international && "(99)9 9999-9999"}
                name='contact_celphone'
                value={data.contact_celphone} 
                onChange={handleChange}
              />
              <Form.Check type="switch" name='international' checked={data.international} onChange={handleChangeCheck} label="Número internacional" />
              {errors?.contact_celphone && <Form.Control.Feedback type='invalid'>{errors?.contact_celphone}</Form.Control.Feedback>}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="9">
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="notificationsInput">
              <Form.Check type="switch" name='notifications' checked={data.notifications} onChange={handleChangeCheck} label="Desejo receber notificações das solicitações por Whatsapp" />
            </Form.Group>
          </Row>
        </div>
        <div className={styles.sectionContainer}>
          <h6>Endereço</h6>
          <Row className="mb-3">
            <Form.Group as={Col} md="5" controlId="StreetInput">
              <Form.Label>Rua</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.street)}
                type="text"
                placeholder="Rua"
                value={data.address.street}
                name='street'
                onChange={handleAddressChange}
              />
              {errors?.street && <Form.Control.Feedback type='invalid'>{errors?.street}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="2" controlId="NumberInput">
              <Form.Label>Número</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.number)}
                type="text"
                placeholder="Número"
                name='number'
                value={data.address.number} 
                onChange={handleAddressChange}
              />
              {errors?.number && <Form.Control.Feedback type='invalid'>{errors?.number}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="DistrictInput">
              <Form.Label>Bairro</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.district)}
                type="text"
                placeholder="Bairro"
                name='district'
                value={data.address.district} 
                onChange={handleAddressChange}
              />
              {errors?.district && <Form.Control.Feedback type='invalid'>{errors?.district}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="2" controlId="StateInput">
              <Form.Label>Estado</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.state)}
                type="text"
                placeholder="Estado"
                name='state'
                value={data.address.state} 
                onChange={handleAddressChange}
              />
              {errors?.state && <Form.Control.Feedback type='invalid'>{errors?.state}</Form.Control.Feedback>}
            </Form.Group>
          </Row>
          <Row className="mb-3">
            <Form.Group as={Col} md="5" controlId="CityInput">
              <Form.Label>Cidade</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.city)}
                type="text"
                placeholder="Cidade"
                name='city'
                value={data.address.city} 
                onChange={handleAddressChange}
              />
              {errors?.city && <Form.Control.Feedback type='invalid'>{errors?.city}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="3" controlId="CEPInput">
              <Form.Label>CEP</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.cep)}
                type="text"
                placeholder="CEP"
                mask="99999-999"
                name='cep'
                value={data.address.cep} 
                onChange={handleAddressChange}
              />
              {errors?.cep && <Form.Control.Feedback type='invalid'>{errors?.cep}</Form.Control.Feedback>}
            </Form.Group>
            <Form.Group as={Col} md="4" controlId="ReferenceInput">
              <Form.Label>Ponto de referência</Form.Label>
              <Form.Control
                isInvalid={Boolean(errors?.complement)}
                type="text"
                placeholder="Ponto de referência"
                value={data.address.complement} 
                name='complement'
                onChange={handleAddressChange}
              />
              {errors?.complement && <Form.Control.Feedback type='invalid'>{errors?.complement}</Form.Control.Feedback>}
            </Form.Group>
          </Row>
        </div>
        <div className={styles.InputContainer}>
          <div className={styles.Buttons}>
            <button className={styles.PrimaryButton} type='submit'>Confirmar</button>
          </div>
        </div>
      </Form>
    );
}


Register.propTypes = {};

Register.defaultProps = {};

export default Register;
