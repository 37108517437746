import { getDoc, getDocs, setDoc, doc, query, collection, updateDoc, where } from "firebase/firestore";
import { db, storage } from "../utils/fbConnector";
import { v4 as uuidv4 } from 'uuid';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";

export function UserService (user) {
    const getAllUsers = () => {
        return new Promise(async (resolve, reject) => {
            let users = [];
            if(!user.userInfo.isAdmin){
                reject("Usuário não possui permissão para continuar");
            }
            let q = query(collection(db, "users"));
            users = await getDocs(q);
            let result = []
            users.forEach(user => {

                const doc = user.data();
                let userType = "Cliente";
                if(doc.isAdmin) {
                    userType = "Administrador";
                } else if (doc.isDriver) {
                    userType = "Motorista";
                }
                const detail = {
                    uid: user.id,
                    name: doc.name,
                    userType: userType,
                    phone: doc.contact_phone || doc.contact_celphone,
                    identification: doc.cpf_cnpj,
                    status: Boolean(doc.nickname) ? "Ativo" : "Pendente",
                    voucher: doc.voucherId
                }
                result.push(detail)
            })
            resolve(result);
        })
    };
    const getFilteredUser = (uid) => {
      return new Promise(async (resolve, reject) => {
          if(!user.userInfo.isAdmin){
              reject("Usuário não possui permissão para continuar");
          }
          const docSnap = await getDoc(doc(db, 'users', uid));
          if (!docSnap.exists()) {
              reject("No such document!");
          }
          const snapshot = docSnap.data();
          let userType = "Cliente";
          if(snapshot.isAdmin) {
              userType = "Administrador";
          } else if (snapshot.isDriver) {
              userType = "Motorista";
          }
          const detail = {
              uid: docSnap.id,
              name: snapshot.name,
              userType: userType,
              phone: snapshot.contact_phone || snapshot.contact_celphone,
              identification: snapshot.cpf_cnpj,
              status: Boolean(snapshot.nickname) ? "Ativo" : "Pendente",
              voucher: snapshot.voucherId
          }
          resolve([detail]);
      })
  };
    const getUser = uid => {
        return new Promise(async (resolve, reject) => {
            const docSnap = await getDoc(doc(db, 'users', uid));
            if (docSnap.exists()) {
                resolve(docSnap.data())
            } else {
                reject("No such document!");
            }
        })
    }
    const getUserByVoucher = voucher => {
        return new Promise(async (resolve, reject) => {
            const q = query(collection(db, "users"), where("voucherId", "==", voucher));
            const docSnap = await getDocs(q);
            const docs = []
            docSnap.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                docs.push(doc.data());
            });
            if (docs.length > 0) {
                resolve(docs)
            } else {
                reject("No such document!");
            }
        })
    }
    const createUser = (uid, data, file=null) => {
        return new Promise(async (resolve, reject) => {
            data.isAdmin = false;
            data.isDriver = false;
            if (!file){
              await setDoc(doc(db, "users", uid), data);
              resolve(uid);
            }
            const imageUUID = uuidv4();
            const storageRef = ref(storage, `images/${imageUUID}`);
            const uploadTask = uploadBytesResumable(storageRef, file);
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                console.log(snapshot);
              },
              (error) => {
                reject(error)
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                  data = {...data, photoUrl: downloadURL};
                  try{
                    await setDoc(doc(db, "users", uid), data)
                    resolve(uid);
                  } catch (e) {
                    reject(e);
                  }
                });
              }
            );
        })
    }
    const updateUser = (uid, data, photo=null) => {
        return new Promise(async (resolve, reject) => {
            const userRef = doc(db, "users", uid);
            if(!photo) {
                await updateDoc(userRef, data);
                resolve()
            }
            const imageUUID = uuidv4();
            const storageRef = ref(storage, `images/${imageUUID}`);
            const uploadTask = uploadBytesResumable(storageRef, photo);
            uploadTask.on(
              "state_changed",
              (snapshot) => {
                console.log(snapshot);
              },
              (error) => {
                reject(error)
              },
              () => {
                getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
                  data = {...data, photoUrl: downloadURL};
                  try{
                    await updateDoc(userRef, data)
                    resolve();
                  } catch (e) {
                    reject(e);
                  }
                });
              }
            );
        }) 
    }
    return {
        getAllUsers,
        getUser,
        getUserByVoucher,
        createUser,
        updateUser,
        getFilteredUser
    }
}