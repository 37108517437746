import React from 'react';
import DataTable from 'react-data-table-component';
import TableExpanded from '../../ui/tableExpanded/tableExpanded';
import { AuthContext } from '../../../providers/AuthProvider';
import styles from './cars.module.scss';
import { CarService } from '../../../services/car.service';
import { CarsColumns, customSort } from '../../../utils/dataTableColumns';
import { DriverService } from '../../../services/driver.service';
import CarFilterModal from '../../ui/Filters/CarFilterModal/CarFilterModal';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Cars extends React.Component{
  static contextType = AuthContext;
  
  data = [];
  driversList = [];
  licensesList = [];

  constructor(props) {
    super(props);
    this.state = {
      showFilterModal: false
    }
    
  }

  async componentDidMount() {
    this.user = this.context.user
    this.carService = new CarService(this.user);
    this.carService.getAllCars().then((result)=> {
      this.data = result;
      this.licensesList = result.map((car) => {return {id: car.uid, name: car.license}});
      if(this.user.userInfo.isAdmin) {
        this.driverService = new DriverService(this.user);
        this.driverService.getAllDrivers().then((driversResult) => {
          this.driversList = driversResult.map(driverData => {return {id: driverData.id, name: driverData.name}});
          this.forceUpdate();
        })
      } else {
        this.forceUpdate();
      }
    })
  }

  setData = (result) => {
    this.data = result;
    this.forceUpdate();
    this.onModalClose();
  }

  onFilterData = (filterData) => {
    this.selectedDriver = filterData.driverName;
    this.selectedLicense = filterData.licenseName;
    if(filterData.licenseId) {
      this.carService.getCar(filterData.licenseId).then(this.setData)
    } else if(filterData.driverId) {
      this.carService.getCarByDriver(filterData.driverId).then(this.setData)
    } else {
      this.carService.getAllCars().then(this.setData)
    }
  }

  onModalClose() {
    this.setState({ showFilterModal: false });
    return this.forceUpdate();
  }

  onRowClick = (uid) => {
    return this.props.navigate("/car/" + uid)
  }
  
  render() {
    if(!this.user) return <div></div>;
    return (
      <div className={styles.Home}>
        <div className={styles.Header}>
          <h1>Carros</h1>
          <div className={styles.HeaderItems}>
            <div className={styles.StatusContainer}>
            </div>
            <div className={styles.buttonContainer }>
              <button className={styles.SecondaryButton} onClick={() => this.props.navigate("/car/create")}>Adicionar Carro</button>
            </div>
          </div>
        </div>
        <div className={styles.ListData}>
          <div className={styles.mdHide}>
            {
              this.user.userInfo.isAdmin && 
              <div className={styles.Filters}>
                <Button variant='outline-warning' className='search-button' onClick={() => this.setState({ showFilterModal: true })}><FontAwesomeIcon icon="fa-solid fa-magnifying-glass"></FontAwesomeIcon></Button>
                <CarFilterModal type='passageiros' show={Boolean(this.state.showFilterModal)} drivers={this.driversList} licenses={this.licensesList} onClose={() => this.onModalClose()} onFilter={this.onFilterData}></CarFilterModal>
                { this.selectedDriver && <h6>Exibindo carro utilizado por: {this.selectedDriver} </h6> }
                { this.selectedLicense && <h6>Exibindo carro placa: {this.selectedLicense} </h6> }
              </div>
            }
            <DataTable
              pagination
              columns={CarsColumns(this.onRowClick)}
              data={this.data}
              sortFunction={customSort}
              noDataComponent="Não existem registros para serem exibidos."
              ></DataTable>
          </div>
          <div className={styles.mdShow}>
            <DataTable
              pagination
              expandableRows 
              expandableRowsComponent={TableExpanded}
              columns={CarsColumns(this.onRowClick, true)}
              data={this.data}
              sortFunction={customSort}
              ></DataTable>
          </div>
        </div>
      </div>
    )
  }
};

export default Cars;