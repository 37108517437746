import { getDoc, getDocs, doc, query, collection, where } from "firebase/firestore";
import { db } from "../utils/fbConnector";
import { CarService } from "./car.service";

export function DriverService (user) {
    

    const getAllDrivers = () => {
        return new Promise(async (resolve, reject) => {
            let users = [];
            //if(user.userInfo.isAdmin){
            //    reject("Usuário não possui permissão para continuar");
            //}
            let q = query(collection(db, "users"), where("isDriver", '==', true));
            users = await getDocs(q);
            let result = []
            await users.forEach(async userData => {
                const doc = userData.data();
                const detail = {
                    id: userData.id,
                    name: doc.name,
                    phone: doc.contact_phone || doc.contact_celphone,
                    identification: doc.cnh,
                    status: Boolean(doc.nickname) ? "Ativo" : "Pendente"
                }
                result.push(detail)
            })
            resolve(result);
        })
    };
    const getDriversExcept = excludeDrivers => {
        return new Promise(async (resolve, reject) => {
            let users = [];
            if(!user.userInfo.isAdmin){
                reject("Usuário não possui permissão para continuar");
            }
            let q = query(collection(db, "users"), where("isDriver", '==', true), where('id', 'not-in', excludeDrivers));
            users = await getDocs(q);
            let result = []
            users.forEach(user => {
                const doc = user.data();
                const detail = {
                    name: doc.name,
                    actualCar: doc.car.uid,
                    phone: doc.contact_phone || doc.contact_celphone,
                    identification: doc.cnh,
                    status: Boolean(doc.nickname) ? "Ativo" : "Pendente"
                }
                result.push(detail)
            })
            resolve(result);
        })
    }
    const getDriver = uid => {
        return new Promise(async (resolve, reject) => {
            const docSnap = await getDoc(doc(db, 'users', uid));
            if (docSnap.exists()) {
                resolve({...docSnap.data(), uid: docSnap.id})
            } else {
                reject("No such document!");
            }
        })
    }
    return {
        getAllDrivers,
        getDriver,
        getDriversExcept
    }
}