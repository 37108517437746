import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './PassengerInput.module.scss';
import { passengerModel } from '../../../models/passenger';
import InputMask from "react-input-mask";
import { Col, Form, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typeahead } from 'react-bootstrap-typeahead';
import { WhatsappService } from '../../../services/whatsapp.service';

const PassengerInput = (props) => {
  const [ data, setData ] = useState(passengerModel)
  const whatsappService = new WhatsappService();
  const [ invalidNumber, setInvalidNumber ] = useState(false);
  const [ invalidBirth, setInvalidBirth ] = useState(false);

  useEffect(() => {
    if(props.data) {
      setData(props.data);
    }
  }, [])

  useEffect(() => {
    setInvalidBirth(false)
    setInvalidNumber(false)
    if(!data.notifications || data.notifications === "false") return;
    if(data.birth === "") {
      setInvalidBirth(true)
    }
    if(data.phone === "") {
      setInvalidNumber(true)
    }
  }, [data])

  const handlePassengerChange = (e) => {
    const selectedPassenger = props.passengersList.find(passenger => passenger.uid === e[0].uid);
    const newData = {...data, ...selectedPassenger};
    setData(newData);
    props.onDataChange(newData, props.index);
  }

  const handleChange = (e) => {
    const newData = {...data, [e.target.name]: e.target.value}
    setData(newData);
    props.onDataChange(newData, props.index);
  }

  const onFocus= ({ target }) => {
    target.setAttribute('autoComplete', 'off');
  }

  const handleBlur = (e) => {
    setInvalidNumber(false);
    if(!data.notifications) {
      return
    }
    whatsappService.verifyNumber(e.target.value, data.international).then(res => {
      if(!res[0]?.exists) {
        setInvalidNumber(true);
      }
    })
  }

  return (
    <div className={styles.sectionContainer}>
      <div className={styles.passengerHeader}>
        <h5>Informações do passageiro</h5>
        {props.index !== 0 && 
          <FontAwesomeIcon icon="fa-solid fa-trash" className={styles.icon} onClick={() => props.onRemove(props.index)}></FontAwesomeIcon>
        }
      </div>
      <Row>
      <Form.Group as={Col} md="12" controlId="passengersList">
          <Form.Label>Selecione um passageiro<strong className='text-danger'>*</strong></Form.Label>
          <Typeahead
            id='filterPssg'
            labelKey="name"
            onChange={handlePassengerChange}
            options={props.passengersList}
            placeholder='Pesquisar'
            onFocus={onFocus}
          />
        </Form.Group>
      <Form.Group as={Col} md="6" controlId="nameInput">
          <Form.Label>Nome <strong className='text-danger'>*</strong></Form.Label>
          <Form.Control
            required
            isInvalid={!data.name}
            name='name'
            type="text"
            placeholder="Nome"
            value={data.name} 
            onChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            Por favor informe o nome do passageiro
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="phoneInput">
          <Form.Label>Telefone/Celular</Form.Label>
          {data.international && <Form.Control.Feedback type='alert'>*(digite o código do país seguido do número de celular. Ex.: '+55....')</Form.Control.Feedback>}
          <InputMask type='text' name='phone' mask={!data.international ? '(99) 9 9999-9999' : ''} className={`form-control ${Boolean(invalidNumber) && 'is-invalid'}` }  placeholder='Telefone' value={data.phone} onChange={handleChange} onBlur={handleBlur}></InputMask>
          {invalidNumber && <Form.Control.Feedback type='invalid'>Número informado não possui Whatsapp.</Form.Control.Feedback>}
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="birthInput">
          <Form.Label>Data de nascimento</Form.Label>
          <InputMask type='text' name='birth' mask="99/99/9999" className={`form-control ${Boolean(invalidBirth) && 'is-invalid'}` } placeholder='Data de nascimento' value={data.birth} onChange={handleChange}></InputMask>
          {(invalidBirth) && <Form.Control.Feedback type='invalid'>Data de nascimento inválida. Por favor, insira uma data válida.</Form.Control.Feedback>}
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="9">
        </Form.Group>
        <Form.Group as={Col} md="3" controlId="celphoneInput">
          <Form.Check type="switch" name='international' checked={data.international} onChange={handleChange} label="Número internacional" />
          <Form.Check type="switch" name='notifications' checked={data.notifications} onChange={handleChange} label="Desejo receber notificações das solicitações por Whatsapp" />
        </Form.Group>
      </Row>
    </div>
  );
};

PassengerInput.propTypes = {
  data: PropTypes.object,
  onDataChange: PropTypes.func,
  onRemove: PropTypes.func,
  index: PropTypes.number,
  passengersList: PropTypes.array
};

PassengerInput.defaultProps = {};

export default PassengerInput;
