import { getDoc, getDocs, addDoc, doc, query, collection, where, updateDoc, deleteDoc } from "firebase/firestore";
import { db } from "../utils/fbConnector";
import { DriverService } from "./driver.service";

export function CarService (user) {
    const driverService = new DriverService(user);

    const getAllCars = () => {
        return new Promise(async (resolve, reject) => {
            let cars = [];
            if(!user.userInfo.isAdmin && !user.userInfo.isDriver){
                reject("Usuário não possui permissão para continuar");
            }
            let q = query(collection(db, "cars"));
            cars = await getDocs(q);
            let result = []
            cars.forEach(car => {
                const doc = car.data();
                const detail = {
                    uid: car.id,
                    brand: doc.brand,
                    model: doc.model,
                    license: doc.license,
                    color: doc.color,
                    status: doc.driverId? 'Alocado': 'Disponível'
                }
                result.push(detail)
            })
            resolve(result);
        })
    };
    const getCar = uid => {
        return new Promise(async (resolve, reject) => {
            const docSnap = await getDoc(doc(db, 'cars', uid));
            if (!docSnap.exists()) reject('No such document!')
            let carData = docSnap.data();
            carData.uid = docSnap.id;
            const car = {
                uid: docSnap.id,
                brand: carData.brand,
                model: carData.model,
                license: carData.license,
                color: carData.color,
                status: carData.driverId? 'Alocado': 'Disponível'
            }
            resolve([car])
        })
    }

    const getCarInfo = uid => {
        return new Promise(async (resolve, reject) => {
            const docSnap = await getDoc(doc(db, 'cars', uid));
            if (!docSnap.exists()) reject('No such document!')
            let carData = docSnap.data();
            carData.uid = docSnap.id;
            if(carData.driverId) {
                driverService.getDriver(carData.driverId).then((result) => {
                    carData = {...carData, driver: {
                        name: result.name,
                        id: result.uid
                    }}
                    resolve(carData)
                })
            } else {
                resolve(carData)
            }
        })
    }

    const getCarByDriver = (driverUid) => {
        return new Promise(async (resolve, reject) => {
            let cars = [];
            
            let q = query(collection(db, "cars"), where('driverId', '==', driverUid));
            cars = await getDocs(q);
            let result = []
            cars.forEach(car => {
                const doc = car.data();
                const detail = {
                    uid: car.id,
                    brand: doc.brand,
                    model: doc.model,
                    license: doc.license,
                    color: doc.color,
                    status: doc.driverId? 'Alocado': 'Disponível'
                }
                result.push(detail)
            })
            if(result.length > 1) reject('Motorista está dirigindo multiplos carros simultaneamente.');
            resolve(result);
        })
    }

    const getCarsByDrivers = (driversList) => {
        return new Promise(async (resolve, reject) => {
            let cars = [];
            
            let q = query(collection(db, "cars"), where('driverId', 'in', driversList));
            cars = await getDocs(q);
            let result = []
            cars.forEach(car => {
                const doc = car.data();
                const detail = {
                    uid: car.id,
                    brand: doc.brand,
                    model: doc.model,
                    license: doc.license,
                    color: doc.color,
                    status: doc.driverId? 'Alocado': 'Disponível',
                    driverId: doc.driverId
                }
                result.push(detail)
            })
            resolve(result);
        })
    }

    const getLocatedCars = () => {
        return new Promise(async (resolve, reject) => {
            let cars = [];
            if(!user.userInfo.isAdmin){
                reject("Usuário não possui permissão para continuar");
            }
            let q = query(collection(db, "cars"), where('driverId', '!=', ''));
            cars = await getDocs(q);
            let result = []
            cars.forEach(car => {
                const doc = car.data();
                const detail = {
                    driverId: car.driverId,
                    uid: car.id,
                    brand: doc.brand,
                    model: doc.model,
                    license: doc.license,
                    color: doc.color,
                    status: doc.driverId? 'Alocado': 'Disponível'
                }
                result.push(detail)
            })
            resolve(result);
        })
    }

    const getAvailableCars = () => {
        return new Promise(async (resolve, reject) => {
            let cars = [];
            if(!user.userInfo.isAdmin && !user.userInfo.isDriver){
                reject("Usuário não possui permissão para continuar");
            }
            let q = query(collection(db, "cars"), where('driverId', '==', ''));
            cars = await getDocs(q);
            let result = []
            cars.forEach(car => {
                const doc = car.data();
                const detail = {
                    uid: car.id,
                    brand: doc.brand,
                    model: doc.model,
                    license: doc.license,
                    color: doc.color
                }
                result.push(detail)
            })
            resolve(result);
        })
    }

    const createCar = (data) => {
        return new Promise(async (resolve, reject) => {
            data.creator = user.uid;
            try{
              const docRef = await addDoc(collection(db, `cars`), data)
              resolve(docRef.id);
            } catch (e) {
              reject(e);
            }
        })
    }

    const changeDriver = (carId, driverId) => {
        return new Promise(async (resolve, reject) => {
            const q = query(collection(db, 'cars'), where('driverId', '==', driverId));
            const actualCarSnapshot = await getDocs(q);
            let actualCar = [];
            actualCarSnapshot.forEach(car => {
                actualCar.push({...car.data(), uid: car.id});
            })
            if(actualCar.length > 1) reject({message: 'Duplicidade encontrada'});
            if(!actualCar.length){
                const data = {driverId: driverId};
                await updateCar(data, carId).then((result) => {
                    resolve();
                });
            } else if(actualCar[0].uid === carId) reject({message: 'Carro já está selecionado pelo cliente'});
            else if(actualCar.length === 1) {
                const oldData = {driverId: ''};
                updateCar(oldData, actualCar[0].uid).then((result) => {
                    const data = {driverId: driverId};
                    updateCar(data, carId).then((result) => {
                        resolve();
                    });
                });
            }
        })
    }

    const updateCar = (data, uid) => {
        return new Promise(async (resolve, reject) => {
            const docRef = doc(db, 'cars', uid)
            const docSnap = await getDoc(docRef);
            delete data.driver;
            if(!docSnap.exists()) reject('Não encontramos o novo carro');
            updateDoc(docRef, data).then((result) => {
                resolve();
            }).catch(e => {
                reject(e)
            })
        })
    }

    const deleteCar = (carUid) => {
        return new Promise(async(resolve, reject) => {
            const result = await deleteDoc(doc(db, 'cars', carUid));
            resolve(result);
        })
    }

    return {
        getAllCars,
        getCarInfo,
        createCar,
        changeDriver,
        getCarsByDrivers,
        getLocatedCars,
        updateCar,
        getCarByDriver,
        getAvailableCars,
        deleteCar,
        getCar
    }
}