import styles from './CarFilterModal.module.scss';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import { Typeahead } from 'react-bootstrap-typeahead';

const CarFilterModal = (props) => {
  const [ filterDriver, setFilterDriver ] = useState({id: '', name: ''});
  const [ filterLicense, setFilterLicense ] = useState({id: '', name: ''});

  const handleDriverChange = (e) => {
    const name = e[0].name
    setFilterDriver({id: e[0].id, name});
    setFilterLicense({id: '', name: ''});
  }

  const handleLicenseChange = (e) => {
    const name = e[0].name
    setFilterLicense({id: e[0].id, name});
    setFilterDriver({id: '', name: ''});
  }

  const handleOnFilter = () => {
    props.onFilter({driverId: filterDriver.id, driverName: filterDriver.name, licenseId: filterLicense.id, licenseName: filterLicense.name});
  }

  const handleOnCleanFilter = () => {
    setFilterLicense({id: '', name: ''});
    setFilterDriver({id: '', name: ''});
    props.onFilter({driverId: '', driverName: '', licenseId: '', licenseName: ''});
  }

  const onFocus= ({ target }) => {
    target.setAttribute('autoComplete', 'off');
  }

  return (
    <Modal show={props.show} onHide={props.onClose} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
          <Modal.Title>Filtros de carros</Modal.Title>
        </Modal.Header>
      <Modal.Body className={styles.SolicitationModal}>
        <Form.Group controlId="licenseInput">
          <Form.Label>Filtre pela placa do carro</Form.Label>
          <Typeahead
            id='id'
            labelKey="name"
            selected={[filterLicense]}
            onChange={handleLicenseChange}
            options={props.licenses}
            placeholder='Pesquisar'
            onFocus={onFocus}
          />
        </Form.Group>
        <Form.Group controlId="driverInput">
          <Form.Label>Filtre por motorista</Form.Label>
          <Typeahead
            id='id'
            labelKey="name"
            selected={[filterDriver]}
            onChange={handleDriverChange}
            options={props.drivers}
            placeholder='Pesquisar'
            onFocus={onFocus}
          />
        </Form.Group>
        <div className={styles.buttons}>
          <button className={styles.PrimaryButton} onClick={handleOnFilter}>Filtrar</button>
          <button className={styles.SecondaryButton} onClick={handleOnCleanFilter}>Limpar filtro</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

CarFilterModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  drivers: PropTypes.array,
  licenses: PropTypes.array
};

CarFilterModal.defaultProps = {};

export default CarFilterModal;
