import { SolicitationStatusModel } from "../../consts/solicitationStatus"

export const formatHomeData = (solicitations) => {
    return solicitations.map((solicitation) => {
        const newDate = new Date(solicitation.date).toLocaleString();
        const status = normalizeSolicitationStatus(solicitation);
        return {
            ...solicitation,
            date: newDate,
            status: status
        }
    })
}

export function normalizeSolicitationStatus(solicitation) {
    const statusKey = Object.keys(SolicitationStatusModel).find(status => SolicitationStatusModel[status].value === solicitation.status)
    return SolicitationStatusModel[statusKey].label;
}

export function getInitialDate(solicitation) {
    const initial_route = solicitation.routeAdresses[defineShippingAddressId(solicitation)];
    return initial_route.address.datetime.toDate().toISOString();
}

export function formatAddress(address) {
    return `${address.address.street}, ${address.address.number}, ${address.address.district}, ${address.address.city}, ${address.address.state} - ${address.address.cep}`
}

export const defineShippingAddressId = (solicitation) => {
  const shippingAddressId = solicitation.shippingAddressId;
  if (shippingAddressId === null) {
    return 0
  }
  if(solicitation.routeAdresses[shippingAddressId + 1].address.datetime) {
    return shippingAddressId + 1;
  }
  return shippingAddressId;
}

const convertToCSV = (objArray) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '\uFEFF';

  for (let i = 0; i < array.length; i++) {
    let line = '';
    for (let index in array[i]) {
      if (line !== '') line += ';';

      line += `"${array[i][index]}"`;
    }
    str += line + '\r\n';
  }
  return str;
}
export const downloadTableCSV = (data) => {
  const csvData = new Blob([convertToCSV(data)], { type: 'application/csv;charset=UTF-8' });
  const csvURL = URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvURL;
  link.download = `relatorio_newpra.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

const convertToPDF = (objArray) => {
  const valuesArray = objArray.reduce((acc, obj) => {
    acc.values.push(Object.values(obj));
    return acc;
  }, {values: []});
  return valuesArray.values;
}

export const downloadTablePDF = (data) => {
  const tableContent = convertToPDF(data);
  const docDefinition = {
    pageOrientation: 'landscape',
    content: [
      {
        style: 'table',
        table: {
          headerRows: 1,
          dontBreakRows: true,
          body: tableContent
        },
        layout: 'noBorders'
      }
    ],
    
    styles: {
      table: {
        fontSize: 7,
      }
    }
  }
  return docDefinition;
}