import styles from './FilterModal.module.scss';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import { SolicitationStatusModel } from '../../../../consts/solicitationStatus';
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

const FilterModal = (props) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [ statusList, setStatusList ] = useState([]);

  const [ filterData, setFilterData ] = useState({driverId: '', startDate: null, endDate: null, statusId:''});

  useEffect(() => {
    registerLocale("pt-BR", ptBR);
    const status = Object.keys(SolicitationStatusModel).map((key) => SolicitationStatusModel[key])
    if(filterData.startDate) {
      setDateRange([filterData.startDate, filterData.endDate])
    }
    setStatusList(status);
  }, []);

  const handleStatusChange = (e) => {
    setFilterData({...filterData, statusId: e.target.value})
  }

  const handleDriverChange = (e) => {
    setFilterData({...filterData, driverId: e.target.value})
  }

  const handleOnFilter = () => {
    props.onFilter(filterData);
  }

  const handleOnCleanFilter = () => {
    setDateRange([null, null])
    setFilterData({driverId: '', startDate: null, endDate: null, statusId:''});
    props.onFilter({driverId: '', startDate: null, endDate: null, statusId:''});
  }

  useEffect(() => {
    const [start, end] = dateRange;
    setFilterData({...filterData, startDate: start, endDate: end});
  }, [dateRange])

  return (
    <Modal show={props.show} onHide={props.onClose} fullscreen="sm-down" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
          <Modal.Title>Filtros de solicitação</Modal.Title>
        </Modal.Header>
      <Modal.Body className={styles.SolicitationModal}>
        <Form.Group controlId="statusInput">
          <Form.Label>Filtre por status da solicitação</Form.Label>
          <Form.Select aria-label="Status" value={filterData.statusId} onChange={handleStatusChange} className={styles.marginTopBottom}>
            <option>Selecione um status</option>
            {
              statusList.map(status => {
                return <option value={status.value}>{status.label}</option>
              })
            }
          </Form.Select>
        </Form.Group>
        {props.drivers.length > 0 &&
          <Form.Group controlId="driverInput">
            <Form.Label>Filtre por motorista</Form.Label>
            <Form.Select aria-label="Driver" value={filterData.driverId} onChange={handleDriverChange} className={styles.marginTopBottom}>
              <option>Selecione um motorista</option>
              {
                props.drivers.map(driver => {
                  return <option value={driver.id}>{driver.name}</option>
                })
              }

            </Form.Select>
          </Form.Group>
        }
        <Form.Group controlId="driverInput">
          <Form.Label>Filtre por datas</Form.Label>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            locale={"pt-BR"}
            dateFormat={"dd/MM/yyyy"}
            onChange={(update) => {
              setDateRange(update);
            }}
            withPortal
            className='form-control'
          />
        </Form.Group>
        <div className={styles.buttons}>
          <button className={styles.PrimaryButton} onClick={handleOnFilter}>Filtrar</button>
          <button className={styles.SecondaryButton} onClick={handleOnCleanFilter}>Limpar filtro</button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

FilterModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onFilter: PropTypes.func,
  drivers: PropTypes.array
};

FilterModal.defaultProps = {};

export default FilterModal;
