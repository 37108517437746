import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './AddressesDetails.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import { AuthContext } from '../../../providers/AuthProvider';
import { AddressService } from '../../../services/address.service';
import InfoModal from '../../ui/InfoModal/InfoModal';

const AddressesDetails = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const uid = useParams().uid;
  const [data, setData] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if(uid) {
      const addressService = new AddressService(user);
      addressService.getAddress(uid).then((result) => {
        setData(result);
      })
    }
  }, [])

  const updateAddress = () => {
    return navigate(`/addresses/update/${uid}`);
  }

  const goBack = () => {
    if(user.userInfo.isAdmin) {
      return navigate('/admin/addresses');
    }
    return navigate('/addresses')
  }

  const deleteAddress = () => {
    setShowDeleteModal(false);
    const addressService = new AddressService(user);
    addressService.deleteAddress(uid).then((result) => {
      goBack();
    })
  }

  if(!data) return <div></div>
  return (
    <div className={styles.AddressesDetails}>
      <div className={styles.Header}>
        <h1>Detalhes do endereço</h1>
      </div>
      <div className={styles.sectionContainer}>
        <h6>Dados do Endereço</h6>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="nameInput">
            <Form.Label>Apelido do endereço</Form.Label>
            <p>{data.name}</p>
          </Form.Group>
          <Form.Group as={Col} md="5" controlId="streetInput">
            <Form.Label>Rua</Form.Label>
            <p>{data.street}</p>
          </Form.Group>
          <Form.Group as={Col} md="2" controlId="numberInput">
            <Form.Label>Número</Form.Label>
            <p>{data.number}</p>
          </Form.Group>
          <Form.Group as={Col} md="5" controlId="districtInput">
            <Form.Label>Bairro</Form.Label>
            <p>{data.district}</p>
          </Form.Group>
          <Form.Group as={Col} md="2" controlId="cepInput">
            <Form.Label>CEP</Form.Label>
            <p>{data.cep}</p>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="cityInput">
            <Form.Label>Cidade</Form.Label>
            <p>{data.city}</p>
          </Form.Group>
          <Form.Group as={Col} md="2" controlId="stateInput">
            <Form.Label>Estado</Form.Label>
            <p>{data.state}</p>
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="complementInput">
            <Form.Label>Complemento</Form.Label>
            <p>{data.complement}</p>
          </Form.Group>
        </Row>
      </div>
      <div className={styles.InputContainer}>
        <div className={styles.Buttons}>
          {
            (user.userInfo.isAdmin || user.uid === data.owner) &&
            <button className={styles.DangerButton} onClick={() => setShowDeleteModal(true)}>Excluir endereço</button>
          }
          {
            (user.userInfo.isAdmin || user.uid === data.owner) &&
            <button className={styles.SecondaryButton} onClick={() => updateAddress()}>Atualizar informações do endereço</button>
          }
          <button className={styles.PrimaryButton} onClick={() => goBack()}>Voltar</button>
        </div>
      </div>
      <InfoModal show={Boolean(showDeleteModal)} message={'Tem certeza de que deseja remover o endereço?'} onClose={() => deleteAddress()} onCancel={() => setShowDeleteModal(false)}></InfoModal>
    </div>
  )
};

AddressesDetails.propTypes = {};

AddressesDetails.defaultProps = {};

export default AddressesDetails;
